exports.generateDeveloperConsoleMessage = function () {
  return (
    "\n" +
    "\n" +
    "            sNNh`  `+ymNNNmho-    `hNNNNNNNNNNNNNNNNNNNs  sNNy-   yNNs\n" +
    "          `dMMMd``yMMMMMMMMMMMd-  `dMMMMMMMMMMMMMMMMMMNs  sMMMMh- yNNs\n" +
    "         .mMMMy `mMMMh:` `-oNMMM/                          .sMMMMd:   \n" +
    "        :NMMMo  oMMMy       :MMMm `hNMMMMMMMMMMMMMMMMMNs     .sNMMMd. \n" +
    " ..    +MMMN/   oMMMy       :MMMm `dMMMMMMMMMMMMMMMMMMNs     .sNMMMd. \n" +
    "dMMm` yMMMm-    `mMMMh:`  -oNMMM/                          .yMMMMd:   \n" +
    "+hdo`dMMMd`      `yMMMMMMMMMMMd:  `dMMMMMMMMMMMMMMMMMMNs  yMMMMh-     \n" +
    "     hNNs          `+ymNMNmho-    `hNMMMMMMMMMMMMMMMMMNs  sNNy-       \n" +
    "\n" +
    "This site is built with Gatsby + Netlify CMS.\n" +
    "Learn more about how this site was built: https://xx\n" +
    "\n" +
    "Open to work. Get in contact with me: https://joeeey.com/contact\n" +
    "\n"
  );
};

exports.generateDeveloperHtmlMessage = function () {
  return "════ ⋆★⋆ ════ Hello fellow developer. Welcome to my site! ════ ⋆★⋆ ════";
};
